import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
// MUI Components
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// MUI Icons
import {
  Apartment,
  ArrowBack,
  CheckCircle,
  Clear,
  DeleteOutlineOutlined,
  EditNote,
  EditOutlined,
  InfoOutlined,
  IosShare,
  LocationOn,
  MoreHoriz,
  RemoveRedEye,
  Share,
} from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSelector } from "react-redux";

import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
} from "@mui/material";
// Custom imports
import LoadingOverlay from "../../../components/LoadingOverlay";
import { PopUpAlert } from "../../../components/PopUpAlert";
import {
  useDeleteReportsMutation,
  useGetReportByIdQuery,
  useSetReportStatusMutation,
  useShareDailyReportMutation,
} from "../../../features/project/modules/dailyReports/dailyReportsApiSlice";
import ReportLocationDetailsTable from "./components/ReportLocationDetailsTable";
import dailyReportsUtil from "./utils/dailyReports";

import {
  ModalLastEdit,
  ModalSeenBy,
  ModalShare,
} from "../../../components/shared";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import { getIcon } from "../Dashboard/Weather/icons";
import CaslContext from "../../../utils/caslContext";
import DelayReportDetails from "./components/DelayReportDetails";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import { selectCurrentGuideMe } from "../../../features/project/projectSlice";
import SkeletonLoading from "../../../components/shared/SkeletonLoading";
import { ManpowerDetails } from "./components/ManpowerDetails";
import pdf from "../../../utils/pdf";
import { useToast } from "../../../contexts/ToastContext";
import { statusStyles } from "../../../utils";
import approvedReport from "../../../assets/dailyReportApproved.png";
import DeleteModal from "./components/Modals/DeleteModal";
import { ReactComponent as SharedIcon } from "../../../assets/icons/Shared.svg";

function ReportDetail({ report }) {
  if (report?.category === 4) {
    return <DelayReportDetails report={report} />;
  }
  if (report?.category === 5) {
    return <ManpowerDetails report={report} />;
  }
  return <ReportLocationDetailsTable report={report} />;
}

function UsersModal({ open, handleClose, title, users }) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {title}
        <IconButton onClick={handleClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ maxHeight: "254px" }}>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          gap={2}
        >
          {users.map((user) => (
            <Box
              display="flex"
              justifyContent="start"
              gap={2}
              alignItems="center"
              minWidth="400px"
              key={user?.id}
            >
              <Avatar
                key={user?.id}
                alt={user?.name}
                src={user?.urlAvatar}
                sx={{
                  bgcolor: "#ffc900",
                  width: 50,
                  height: 50,
                  fontSize: 16,
                  boxShadow: "0 0 4px 0 rgba(200, 200, 200, 1)",
                  opacity: user?.bActive ? 1 : 0.5,
                }}
                style={{
                  border: "0.8px solid white",
                }}
              >
                {`${user?.firstName[0]}${user?.lastName[0]}`}
              </Avatar>
              <Box>
                <Typography color="#4B4B4B" fontSize="16px" fontWeight="500">
                  {user.name}
                </Typography>
                <Typography color="#8E8E8E" fontSize="14px" fontWeight="400">
                  {user.email}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

function UserGroup({ users, label }) {
  const { t: tGeneral } = useTranslation("general");
  const [open, setOpen] = useState(false);

  if (!users.length) {
    return null;
  }

  return (
    <Box
      display="flex"
      justifyContent="start"
      gap={1}
      alignItems="center"
      onClick={() => setOpen(true)}
    >
      <AvatarGroup
        max={3}
        sx={{
          justifyContent: "flex-end",
          "& .MuiAvatar-root": {
            width: 24,
            height: 24,
            fontSize: 16,
          },
          "& .MuiTooltip-tooltip": {
            border: "solid skyblue 1px",
            color: "deepskyblue",
          },
          "& .MuiAvatarGroup-root": {
            marginX: "auto",
            textAlign: "center",
          },
        }}
      >
        {users.map((user) => {
          const name = `${user?.firstName[0]}${user?.lastName[0]}`;
          return (
            <Avatar
              key={user?.id}
              alt={user?.name}
              src={user?.urlAvatar}
              sx={{
                bgcolor: "#ffc900",
                width: 50,
                height: 50,
                fontSize: 16,
                boxShadow: "0 0 4px 0 rgba(200, 200, 200, 1)",
                opacity: user?.bActive ? 1 : 0.5,
              }}
              style={{
                border: "0.8px solid white",
              }}
            >
              {name}
            </Avatar>
          );
        })}
      </AvatarGroup>
      <Typography color="#8E8E8E" fontSize="12px" fontWeight="500">
        {label}{" "}
        {users.length > 1
          ? `${users.length}+ ${tGeneral("people")}`
          : users[0]?.name}
      </Typography>
      <UsersModal
        open={open}
        handleClose={(e) => {
          setOpen(false);
          e.stopPropagation();
        }}
        title={label}
        users={users}
      />
    </Box>
  );
}

function Header({ report }) {
  const navigate = useNavigate();
  const toast = useToast();
  const { idEnterprise, idProject, idReport } = useParams();

  const token = useSelector(selectCurrentToken);

  const [isLastSeenOpen, setIsLastSeenOpen] = useState(false);
  const [isLastEditOpen, setIsLastEditOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [menuRef, setMenuRef] = useState(null);
  const [shareMenuRef, setShareMenuRef] = useState(null);
  const [infoMenuRef, setInfoMenuRef] = useState(null);
  const [editMenuRef, setEditMenuRef] = useState(null);
  const [deleteMenuRef, setDeleteMenuRef] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [openApprovedDialog, setOpenApprovedDialog] = useState(false);
  const handleOpen = () => {
    setOpenApprovedDialog(true);
  };
  const handleClose = (e) => {
    setOpenApprovedDialog(false);
    e.stopPropagation();
  };

  const ability = useContext(CaslContext);
  const currentUser = useSelector(selectCurrentUser);

  const { t: tGeneral } = useTranslation("general");
  const { t: tWeather } = useTranslation("weather");
  const { t: tDailyReports } = useTranslation("dailyReports");
  const { t: tStatus } = useTranslation("status");

  const [
    setReportStatus,
    {
      isSuccess: isSetReportStatusSuccess,
      isLoading: isSetReportStatusLoading,
      isError: isSetReportStatusError,
    },
  ] = useSetReportStatusMutation();

  const [
    shareDailyReport,
    {
      isSuccess: isShareSuccess,
      error: shareError,
      isError: isShareError,
      isLoading: isShareLoading,
    },
  ] = useShareDailyReportMutation();

  const [
    deleteReports,
    {
      isLoading: isDeleteReportsLoading,
      isSuccess: isDeleteReportsSuccess,
      isError: isDeleteReportsError,
    },
  ] = useDeleteReportsMutation();

  const category = useMemo(() => {
    if (report) {
      const categoryStyle = dailyReportsUtil.getCategory(report.category);
      return {
        ...categoryStyle,
        label: tDailyReports(categoryStyle.label),
      };
    }
    return dailyReportsUtil.getCategory(1);
  }, [report, tDailyReports]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const showApprove = useMemo(() => {
    if (report) {
      return (
        report.status === "open" &&
        (ability.can("superAdmin", "dailyReports") ||
          report.assignedTo.indexOf(currentUser.id) > -1)
      );
    }
    return false;
  }, [report, currentUser, ability]);

  const isShared = useMemo(() => {
    if (report && idProject) {
      return report.sharedWith.indexOf(idProject) > -1;
    }
    return false;
  }, [report, idProject]);

  const { icon } = useMemo(() => {
    if (report && report.weatherInfo) {
      if (report.weatherInfo.translationCode && report.weatherInfo.icon) {
        return {
          icon: `https://openweathermap.org/img/wn/${report.weatherInfo.icon}@2x.png`,
          label: tWeather(report.weatherInfo.translationCode.toLowerCase()),
        };
      }
      return getIcon(report.weatherInfo.weatherCode);
    }
    return { icon: "", label: "" };
  }, [report, tWeather]);

  const approveReport = async () => {
    setReportStatus({
      id: report?.id,
      status: "close",
    }).unwrap();
  };

  const exportPDF = async () => {
    toast.showLoading(idReport, tGeneral("downloading"), report.title);
    await pdf({
      data: {},
      token,
      url: `${process.env.REACT_APP_BACKEND_URL}pdf/${idEnterprise}/${idProject}/daily-reports/${idReport}`,
      download: report.title,
      module: "dailyReport",
      object: report,
      tGeneral,
    });
    toast.hideLoading(idReport);
  };

  const handleShareDailyReport = (idProjects) => {
    shareDailyReport({ idDailyReport: idReport, shareWith: idProjects });
  };

  const handleDelete = async () => {
    deleteReports({ ids: [report.id] });
  };

  useEffect(() => {
    if (isSetReportStatusError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
    }
    if (isSetReportStatusSuccess) {
      handleOpen();
    }
  }, [
    isSetReportStatusError,
    isSetReportStatusSuccess,
    tGeneral,
    goBack,
    tDailyReports,
  ]);

  useEffect(() => {
    if (isShareSuccess) {
      PopUpAlert("success", tGeneral("shared"), tGeneral("sharedSuccessfully"));
      setIsShareOpen(false);
    }
    if (isShareError) {
      PopUpAlert("error", "Error", shareError.message);
      setIsShareOpen(false);
    }
  }, [isShareSuccess, isShareError, shareError, tGeneral]);

  useEffect(() => {
    if (isDeleteReportsSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        `${tDailyReports("reports")} ${tGeneral("deletedSuccessfully")}`,
      );
      setDeleteModalOpen(false);
    } else if (isDeleteReportsError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      setDeleteModalOpen(false);
    }
  }, [isDeleteReportsSuccess, isDeleteReportsError, tDailyReports, tGeneral]);

  return (
    <Box width="100%">
      <LoadingOverlay open={isSetReportStatusLoading} />
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        height="32px"
        pt={4}
        pr={4}
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" columnGap={3}>
          <IconButton title="Back" onClick={goBack}>
            <ArrowBack />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center" columnGap={3}>
          <Typography fontSize="14px" fontWeight="medium">
            {moment(report?.createdDate).format("D, MMM, YYYY")}
          </Typography>
          <Box display="flex" justifyContent="start" alignItems="center">
            <img src={icon} alt="weather" style={{ height: 32 }} />
            <Typography fontSize="1rem">
              {Math.round(report?.weatherInfo?.temperature) || "N/A"}°
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        width="100%"
        height="28px"
        pt={4}
        pl="12px"
        pr={4}
        justifyContent="space-between"
      >
        <Box>
          <Typography fontSize="14px" fontWeight="medium">
            #{report.number}
          </Typography>
        </Box>
        <Box>
          <Typography color="#8E8E8E" fontSize="12px" fontWeight="medium">
            {report?.weatherInfo?.name.split(",").slice(0, 2).join(", ")}
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        width="100%"
        height="54px"
        pt={4}
        pl="12px"
        pr={4}
        justifyContent="space-between"
      >
        <Typography fontSize="34px" fontWeight="700">
          {report.title}
        </Typography>

        <Box display="flex" justifyContent="start" alignItems="center">
          {!isShared && showApprove && (
            <Button
              variant="contained"
              sx={{
                p: "2px 16px",
                textTransform: "capitalize",
                borderRadius: "16px",
                height: "28px",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
              onClick={approveReport}
            >
              <CheckCircle sx={{ color: "white" }} />
              <Typography fontWeight="medium" fontSize="1rem">
                {tGeneral("approve")}
              </Typography>
              {openApprovedDialog && (
                <Dialog open={openApprovedDialog} onClose={handleClose}>
                  <DialogContent>
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      gap={2}
                    >
                      <img
                        src={approvedReport}
                        alt="approved-report"
                        style={{ width: "352px", height: "226px" }}
                      />
                      <Typography
                        fontSize="24px"
                        fontWeight="700"
                        whiteSpace="pre"
                        textAlign="center"
                      >
                        {tDailyReports("approvedCorrectly")}
                      </Typography>
                      <Typography
                        fontSize="16px"
                        fontWeight="500"
                        whiteSpace="pre"
                        textAlign="center"
                      >
                        {tDailyReports("continueNavigate")}
                      </Typography>
                      <Button
                        variant="contained"
                        sx={{
                          p: "2px 16px",
                          textTransform: "capitalize",
                          borderRadius: "16px",
                          height: "28px",
                          width: "120px",
                          margin: "auto",
                        }}
                        onClick={handleClose}
                      >
                        {tGeneral("continue")}
                      </Button>
                    </Box>
                  </DialogContent>
                </Dialog>
              )}
            </Button>
          )}
          {report.status === "close" && (
            <Box
              sx={{
                p: "2px 16px",
                textTransform: "capitalize",
                borderRadius: "16px",
                height: "28px",
                display: "flex",
                alignItems: "center",
                gap: 1,
                color: "#C68D08",
                bgcolor: "#FCF18C",
              }}
            >
              <CheckCircle sx={{ color: "#E6B60C" }} />
              {tGeneral("approved")}
            </Box>
          )}

          <IconButton
            aria-label="options"
            onClick={(e) => setMenuRef(e.currentTarget)}
          >
            <MoreHoriz />
          </IconButton>
          <Menu
            id="options"
            anchorEl={menuRef}
            open={Boolean(menuRef)}
            onClose={() => setMenuRef(null)}
          >
            <IconButton
              aria-label="share"
              onClick={(e) => setShareMenuRef(e.currentTarget)}
            >
              <IosShare />
            </IconButton>
            <IconButton
              aria-label="info"
              onClick={(e) => setInfoMenuRef(e.currentTarget)}
            >
              <InfoOutlined />
            </IconButton>
            <IconButton
              aria-label="edit"
              onClick={(e) => setEditMenuRef(e.currentTarget)}
            >
              <EditOutlined />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={(e) => setDeleteMenuRef(e.currentTarget)}
            >
              <DeleteOutlineOutlined />
            </IconButton>
          </Menu>

          {/* Share Menu */}
          <Menu
            id="share"
            anchorEl={shareMenuRef}
            open={Boolean(shareMenuRef)}
            onClose={() => setShareMenuRef(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={exportPDF}>
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText>{tDailyReports("exportPdf")}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => setIsShareOpen(true)}>
              <ListItemIcon>
                <Share />
              </ListItemIcon>
              <ListItemText>{tGeneral("share")}</ListItemText>
            </MenuItem>
          </Menu>

          {/* Info Menu */}
          <Menu
            id="info"
            anchorEl={infoMenuRef}
            open={Boolean(infoMenuRef)}
            onClose={() => setInfoMenuRef(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={() => setIsLastSeenOpen(true)}>
              <ListItemIcon>
                <RemoveRedEye />
              </ListItemIcon>
              <ListItemText>{tDailyReports("lastSeen")}</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => setIsLastEditOpen(true)}>
              <ListItemIcon>
                <EditNote />
              </ListItemIcon>
              <ListItemText>{tGeneral("lastEdit")}</ListItemText>
            </MenuItem>
          </Menu>

          {/* Edit Menu */}
          <Menu
            id="info"
            anchorEl={editMenuRef}
            open={Boolean(editMenuRef)}
            onClose={() => setEditMenuRef(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={() =>
                navigate(
                  `/enterprise/${idEnterprise}/project/${idProject}/dailyReports/form/${report.id}`,
                  { replace: true },
                )
              }
            >
              <ListItemIcon>
                <EditNote />
              </ListItemIcon>
              <ListItemText>{tGeneral("edit")}</ListItemText>
            </MenuItem>
          </Menu>

          {/* Delete Menu */}
          <Menu
            id="delete"
            anchorEl={deleteMenuRef}
            open={Boolean(deleteMenuRef)}
            onClose={() => setDeleteMenuRef(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={() => setDeleteModalOpen(true)}>
              <ListItemIcon>
                <DeleteOutlineOutlined />
              </ListItemIcon>
              <ListItemText>{tGeneral("delete")}</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pr={4}
      >
        <Box
          display="flex"
          alignItems="center"
          height="40px"
          pt={3}
          pl="12px"
          justifyContent="start"
          gap={1}
        >
          <Chip
            label={tStatus(report.status)}
            sx={[
              {
                color: "white",
                fontWeight: "medium",
                fontSize: "1rem",
                minWidth: "92px",
              },
              statusStyles[report.status],
            ]}
          />
          <Chip
            label={category.label}
            sx={[
              {
                fontWeight: "medium",
                fontSize: "1rem",
                minWidth: "92px",
                backgroundColor: "#EEEEEE",
              },
            ]}
          />
          <Chip
            label={report.bPrivate ? tGeneral("private") : tGeneral("public")}
            sx={[
              {
                fontWeight: "medium",
                fontSize: "1rem",
                minWidth: "92px",
                backgroundColor: "#EEEEEE",
              },
            ]}
          />
        </Box>

        {report.status === "close" && (
          <Typography fontSize="12px" fontWeight="500" color="#8E8E8E" component={'div'}>
            {`${tDailyReports("approvedAtAndBy", {
              date: moment(report.closeDate).format("DD MMMM YYYY"),
            })} `}
            <Typography
              color="#C68D08"
              display="inline"
              fontSize="12px"
              fontWeight="500"
              sx={{ textDecoration: "underline" }}
            >
              {report.approvedByUser?.name}
            </Typography>
          </Typography>
        )}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        width="100%"
        height="60px"
        pt={0}
        pl="12px"
        pr={4}
        justifyContent="space-between"
      >
        <Box display="flex" justifyContent="start" gap={1}>
          <UserGroup
            users={report.creatorsUsers}
            label={tGeneral("createdBy")}
          />
          <UserGroup
            users={report.assignedToUsers}
            label={tGeneral("assigned")}
          />
          <UserGroup
            users={report.distributionListUsers}
            label={tGeneral("distributionList")}
          />
        </Box>
        {report.idProject !== idProject &&
          report.sharedWith.includes(idProject) && (
            <Box display="flex" alignItems="start">
              <SvgIcon sx={{ width: "32px", height: "32px" }}>
                <SharedIcon />
              </SvgIcon>

              <Typography fontSize="12px" fontWeight="500" color="#8E8E8E">
                {tDailyReports("sharedFrom")}{" "}
                <Typography
                  color="#C68D08"
                  display="inline"
                  fontSize="12px"
                  fontWeight="500"
                  sx={{ textDecoration: "underline" }}
                >
                  {report.projectName}
                </Typography>
              </Typography>
            </Box>
          )}
      </Box>

      <ModalSeenBy
        isOpen={isLastSeenOpen}
        onClose={() => setIsLastSeenOpen(false)}
        idObject={idReport}
      />
      <ModalLastEdit
        isOpen={isLastEditOpen}
        onClose={() => setIsLastEditOpen(false)}
        idObject={idReport}
      />
      <ModalShare
        isOpen={isShareOpen}
        onClose={() => setIsShareOpen(false)}
        moduleName="dailyReport"
        onShare={handleShareDailyReport}
        objectName={report?.title}
        sharedWith={report?.sharedWith ?? []}
        isLoading={isShareLoading}
      />

      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        handleDelete={handleDelete}
        action="multi"
        isLoading={isDeleteReportsLoading}
      />
    </Box>
  );
}

function Locations({ report }) {
  const { t: tGeneral } = useTranslation("general");

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      bgcolor="#F5F5F5"
      height="calc(100% - 220px)"
    >
      <Box flex={1} sx={{ overflow: "auto" }} p="1rem">
        <Box display="flex" alignItems="center" columnGap="0.5rem">
          {report?.category === 5 && <Apartment sx={{ color: "#E6B60C" }} />}
          {report?.category !== 5 && report?.category !== 4 && (
            <LocationOn sx={{ color: "#E6B60C" }} />
          )}
          <Typography fontSize="1.25rem" fontWeight="bold">
            {report?.category === 5
              ? tGeneral("enterprises")
              : tGeneral(report?.category === 4 ? "" : "locations")}
          </Typography>
        </Box>
        <Box>
          <ReportDetail report={report} />
        </Box>
      </Box>
    </Box>
  );
}

function DailyReportDetails() {
  const navigate = useNavigate();
  const { idReport } = useParams();
  const { t: tDailyReports } = useTranslation("dailyReports");

  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const {
    data: report,
    isLoading,
    isSuccess,
    isError,
    refetch,
  } = useGetReportByIdQuery(idReport);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 1 &&
      (currentGuideMe === "daily-reports-view" ||
        currentGuideMe === "reports-view-work-progress" ||
        currentGuideMe === "reports-view-problem" ||
        currentGuideMe === "reports-view-delay") &&
      isSuccess &&
      report
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, isSuccess, report, setState]);

  if (isLoading) {
    return <SkeletonLoading />;
  }

  return (
    <Box height="100%">
      {isSuccess && (
        <>
          <Header report={report} />

          <Locations report={report} />
        </>
      )}
      {isError && (
        <>
          {/* Top bar */}
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            width={1}
            paddingBottom={3}
          >
            <IconButton title="Back" onClick={goBack}>
              <ArrowBackIcon />
            </IconButton>
          </Box>
          <Box
            width={1}
            display="flex"
            flexDirection="column"
            gap={1}
            alignItems="center"
          >
            <Typography fontSize={24} fontWeight="bold" textAlign="center">
              {tDailyReports("fetchReportIssue")}
            </Typography>
            <Button
              variant="contained"
              startIcon={<RefreshIcon />}
              onClick={() => refetch()}
            >
              {tDailyReports("retry")}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default DailyReportDetails;
