// Hooks
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
// MUI Components
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate, useParams } from "react-router";
// Custom components
// Redux: reducers
import { useTranslation } from "react-i18next";
// Redux: queries

// Icons
import {
  Add,
  Apps,
  ArrowForwardIos,
  RemoveRedEye,
  Search,
  TableRows,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import NoRfis from "../../assets/rfi/NoRfis.svg";
import { ModalCreateProject } from "../../components/enterpriseDashboard/ModalCreateProject";
import ProjectCard from "../../components/enterpriseDashboard/ProjectCard";
import {
  ModalWithAction,
  StyledMaterialReactTable,
  TitleWidgets,
  TooltipIconButton,
} from "../../components/shared";
import {
  useChangeDashboardViewMutation,
  useGetAdminUsersQuery,
  useGetEnterpriseByIdQuery,
  useGetUserProjectsByEnterpriseQuery,
} from "../../features/enterprise/enterpriseApiSlice";
import { setAdminUsers } from "../../features/enterprise/enterpriseSlice";
import Can from "../../utils/can";
import { PopUpAlert } from "../../components/PopUpAlert";
import { usePaginatedProjects } from "../../utils/usePaginatedProjects";

function GridDisplay({
  projectList,
  props,
  onEndReach = () => {},
  refetch = () => {},
}) {
  const [loadingMore, setLoadingMore] = useState(false);
  const containerRef = useRef(null);
  const observer = useRef();

  const { isLoading, noMoreResults, noResults } = props;

  const { t: tGeneral } = useTranslation("general");

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading || noMoreResults) return; // Stop observing if loading or no more posts
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setLoadingMore(true); // Trigger loading of new posts by changing page number
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, noMoreResults],
  );

  useEffect(() => {
    if (loadingMore && !noMoreResults && !noResults) {
      onEndReach();
      setLoadingMore(false);
    }
  }, [loadingMore, noMoreResults, noResults, onEndReach]);

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      gridTemplateRows="repeat(auto-fill, 230px)"
      columnGap="10px"
      rowGap="20px"
      p="10px"
      height="92vh"
      sx={{ overflowY: "auto" }}
      ref={containerRef}
    >
      {projectList.map((project) => (
        <Grid item xs={12} md={6} key={project.id}>
          <ProjectCard project={project} />
        </Grid>
      ))}
      {projectList.length > 0 && projectList.length % 2 !== 0 && (
        <Box display="flex" width="100%" height="100%" />
      )}
      <div
        ref={lastElementRef}
        style={{ height: "130px", textAlign: "center" }}
      >
        <Box
          display="flex"
          width="200%"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          {!noMoreResults && !noResults && isLoading && (
            <CircularProgress color="primary" />
          )}
          {noMoreResults && !isLoading && (
            <p style={{ textAlign: "center", margin: "30px 0" }}>
              <b>{tGeneral("noMoreResults")}</b>
            </p>
          )}
          {noResults && (
            <p style={{ textAlign: "center", margin: "30px 0" }}>
              <b>{tGeneral("noResults")}</b>
            </p>
          )}
        </Box>
      </div>
    </Box>
  );
}

function TableDisplay({ projectList }) {
  const { t: tGeneral } = useTranslation("general");
  const navigate = useNavigate();

  const columns = [
    {
      id: "bullet",
      header: "",
      columnDefType: "display", // turns off data column features like sorting, filtering, etc.
      Cell: () => <ArrowForwardIos sx={{ fontSize: "0.9rem" }} />,
    },
    {
      header: tGeneral("projectName"),
      accessorKey: "projectName",
      size: 130,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: tGeneral("status"),
      accessorKey: "bActive",
      Cell: ({ cell }) => (
        <Chip
          sx={{
            backgroundColor: cell.getValue() ? "#5ce381" : "#c2c3c3",
            color: "white",
            fontSize: 12,
            width: "100px",
            fontWeight: "medium",
            textOverflow: "break-line",
          }}
          label={(cell.getValue()
            ? tGeneral("active")
            : tGeneral("inactive")
          ).toUpperCase()}
        />
      ),
      size: 70,
    },
    {
      header: tGeneral("manager"),
      accessorKey: "constructionManager",
      size: 100,
      Cell: ({ cell }) => cell.getValue() || tGeneral("noManager"),
    },
    {
      header: tGeneral("constructionDirector"),
      accessorKey: "constructionDirector",
      size: 100,
      Cell: ({ cell }) => cell.getValue() || tGeneral("noConstructionDirector"),
    },
    {
      header: tGeneral("architect"),
      accessorKey: "architect",
      size: 100,
      Cell: ({ cell }) => cell.getValue() || tGeneral("noArchitect"),
    },
    {
      header: tGeneral("projectEngineer"),
      accessorKey: "projectEngineer",
      size: 100,
      Cell: ({ cell }) => cell.getValue() || tGeneral("noProjectEngineer"),
    },
  ];

  return (
    <StyledMaterialReactTable
      data={[...projectList]}
      columns={columns}
      renderRowActions={({ row }) => (
        <TooltipIconButton
          size="small"
          icon={<RemoveRedEye fontSize="small" />}
          label={tGeneral("view")}
          onClick={() => {
            localStorage.removeItem("reportsFilters");
            navigate(`../project/${row.id}/dashboard`);
          }}
          color="black"
        />
      )}
    />
  );
}

function ProjectListContent({
  handleOpenCreate,
  preferences,
  total,
  temporalProjectsTable,
}) {
  const { idEnterprise } = useParams();
  const { t: tGeneral } = useTranslation("general");

  const [searchQuery, setSearchQuery] = useState("");
  const [displayMode, setDisplayMode] = useState("grid");

  const currentEnterprise = useGetEnterpriseByIdQuery(idEnterprise, {
    skip: !idEnterprise,
  });

  const {
    paginated: projects,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
    onEndReach,
    noResults,
    noMoreResults,
  } = usePaginatedProjects({
    searchQuery,
    idEnterprise,
    total,
  });

  const [changeDashboardView] = useChangeDashboardViewMutation();

  const onChangeSearch = (query) => {
    setSearchQuery(query);
  };

  const projectList = useMemo(() => {
    if (!projects) return [];
    return projects.sort((a, b) => a.projectName.localeCompare(b.projectName));
  }, [projects]);

  useEffect(() => {
    if (preferences?.view === 1) {
      setDisplayMode("grid");
    } else {
      setDisplayMode("table");
    }
  }, [preferences]);

  // Temporal (table)
  const projectListTemporal = useMemo(() => {
    if (!temporalProjectsTable) return [];
    return temporalProjectsTable
      .filter((project) =>
        project.projectName.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      .sort((a, b) => a.projectName.localeCompare(b.projectName));
  }, [temporalProjectsTable, searchQuery]);

  const changeView = async (val) => {
    try {
      const body = {
        idEnterprise,
        view: val === "grid" ? 1 : 2,
      };
      await changeDashboardView(body).unwrap();
    } catch (err) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
    }
  };

  if (isLoading) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Skeleton width={500} height={400} />
        </Grid>
      </Grid>
    );
  }

  if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  if (!isLoading && !isFetching && total === 0) {
    return (
      <Box sx={{ width: "100%" }}>
        <ButtonGroup sx={{ alignItems: "center", width: "50%" }}>
          <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
            {tGeneral("projects")}
          </Typography>
        </ButtonGroup>
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "center",
            mt: 10,
          }}
        >
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: "bold",
              textAlign: "center",
              width: "100%",
            }}
          >
            {tGeneral("noProjects")}
          </Typography>
          <Can I="view" a="enterpriseInfo">
            <Typography
              sx={{
                fontSize: 15,
                color: "#B0B7C1",
                textAlign: "center",
                mt: 2,
                width: "100%",
              }}
            >
              {tGeneral("createButton")}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              my="15px"
              width="100%"
            >
              <Button
                sx={{
                  color: "black",
                  backgroundColor: "#ffc900",
                  ":hover": {
                    color: "black",
                    backgroundColor: "#d9ab00",
                  },
                  py: "0.5rem",
                }}
                startIcon={<Add />}
                variant="contained"
                onClick={handleOpenCreate}
                data-tour="add-rfi-1"
              >
                {tGeneral("createProject")}
              </Button>
            </Box>
          </Can>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "right",
            }}
          >
            <img src={NoRfis} alt="NoRfis" style={{ width: "90%" }} />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box overflow="hidden">
      <TitleWidgets
        moduleName="project"
        addFunction={handleOpenCreate}
        title={tGeneral("projects")}
        titleAdornment={
          <Can I="view" a="projectCount">
            <Box
              sx={{
                display: "flex",
                marginLeft: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Chip
                size="small"
                label={
                  currentEnterprise?.data?.planType === "user"
                    ? // ? projectList?.length
                      total
                    : // : `${projectList?.length}
                      `${total} / ${currentEnterprise?.data?.projectsLimit}`
                }
                sx={{
                  fontWeight: "500",
                  backgroundColor: "#EBEBEB",
                  fontSize: 13,
                  color: "#3f3f3f",
                }}
              />
            </Box>
          </Can>
        }
        showMoreOptions={false}
        customElement={
          <>
            <Tabs
              value={displayMode}
              onChange={(_, val) => {
                changeView(val);
                setDisplayMode(val);
              }}
            >
              <Tab value="grid" icon={<Apps />} />
              <Tab value="table" icon={<TableRows />} />
            </Tabs>
            <TextField
              id="searchBar"
              margin="dense"
              variant="outlined"
              type="text"
              size="small"
              label={tGeneral("search")}
              value={searchQuery}
              onChange={(e) => {
                onChangeSearch(e.target.value);
              }}
              sx={{ width: "30%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </>
        }
      />
      {/* Mobile searchbar */}
      <Box
        width={1}
        display={{ xs: "flex", md: "none" }}
        alignItems="center"
        marginBottom={2}
      >
        <TextField
          id="searchBar"
          margin="dense"
          variant="outlined"
          type="text"
          label={tGeneral("search")}
          value={searchQuery}
          onChange={(e) => {
            onChangeSearch(e.target.value);
          }}
          sx={{ width: "100%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {displayMode === "grid" ? (
        <GridDisplay
          projectList={projectList}
          props={{
            isLoading: isLoading || isFetching,
            noResults,
            noMoreResults,
          }}
          onEndReach={onEndReach}
          refetch={refetch}
        />
      ) : (
        <TableDisplay projectList={projectListTemporal} />
      )}
    </Box>
  );
}

function ProjectsList() {
  const dispatch = useDispatch();
  const { idEnterprise } = useParams();
  // Translations
  const { t: tGeneral } = useTranslation("general");

  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
  const [limitProjectOpen, setLimitProjectOpen] = useState(false);

  const currentEnterprise = useGetEnterpriseByIdQuery(idEnterprise, {
    skip: !idEnterprise,
  });

  // Fetching projects from get projects query
  const { data: projects, isSuccess } =
    useGetUserProjectsByEnterpriseQuery(idEnterprise);

  const { data: adminUsers } = useGetAdminUsersQuery(idEnterprise);

  const handleOpenCreate = () => {
    if (
      currentEnterprise?.data?.planType.match("project") &&
      currentEnterprise?.data?.activeProjects >=
        currentEnterprise?.data?.projectsLimit
    ) {
      setLimitProjectOpen(true);
    } else {
      setCreateProjectModalOpen(true);
    }
  };

  useEffect(() => {
    if (adminUsers) {
      dispatch(setAdminUsers(adminUsers));
    }
  }, [projects, adminUsers, dispatch, isSuccess]);
  return (
    <>
      <ProjectListContent
        handleOpenCreate={handleOpenCreate}
        preferences={projects?.preferences}
        total={projects?.projects?.length}
        temporalProjectsTable={projects?.projects}
      />
      {createProjectModalOpen && (
        <ModalCreateProject
          isOpen={createProjectModalOpen}
          onClose={() => setCreateProjectModalOpen(false)}
        />
      )}
      <ModalWithAction
        showCancel={false}
        title={tGeneral("projectLimitReached")}
        content={tGeneral("projectAddWarning")}
        onClose={() => setLimitProjectOpen(false)}
        isOpen={limitProjectOpen}
        action={() => setLimitProjectOpen(false)}
        actionLabel={tGeneral("accept")}
      />
    </>
  );
}

export default ProjectsList;
