import { tour } from "./tour";

export const dailyReports = {
  ...tour,
  moduleElement: "Reportes",

  dailyReports: "Reportes Diarios",
  reports: "Reportes",
  emptyReportsTitle: "No hay reportes creados por el momento",
  emptyReportsContent:
    "Si quieres añadir uno, te invitamos a hacer click al botón para comenzar el proceso",
  emptyInactiveReportsTitle: "No hay reportes en la papelera por el momento",
  emptyInactiveReportsContent: "Al eliminar un reporte, éste aparecerá aquí",
  addReport: "Añadir Reporte",
  addDailyReport: "Añadir Reporte Diario",
  editReport: "Editar Reporte",
  dueDate: "Fecha de entrega",
  newReport: "Nuevo reporte",
  creators: "Creadores",
  assigned: "Asignados",
  distributionList: "Lista de Distribución",
  private: "Privado",
  num: "Num",
  title: "Título",
  status: "Estado",
  closedDate: "Fecha de cierre",
  actions: "Acciones",
  detail: "Detalle",
  exportPdf: "Exportar PDF",
  exportCvs: "Exportar CVS",
  fetchReportIssue: "Hubo un problema al cargar este reporte",
  retry: "Reintentar",
  createdDate: "Fecha de creación",
  atHour: "a las",
  lastSeen: "Ultimas visualizaciones",
  lastEdit: "Ultimas ediciones",
  approveReport: "Aprobar Reporte",
  dailyReportsRecycleBin: "Papelera (reportes)",
  deleteDailyReports: "Eliminar Daily Reports",
  confirmDelete:
    "¿Estás seguro de eliminar los reportes diarios seleccionado(s)? Al realizar esta acción, no podrá revertirse",
  successRecovered: "Reporte Diario restaurado exitosamente",
  errorRecovered: "No se puede restaurar el Reporte Diario",
  successDeleted: "Reporte Diario eliminado exitosamente",
  errorDeleted: "No se puede eliminar el Reporte Diario",
  approvedReport: "Reporte aprobado exitosamente",
  errorApprovedReport: "No se puede aprobar el reporte",
  deleteReport: "Eliminar Reporte",
  report: "Reporte",
  addDescription: "Añadir una descripción",
  // other
  obligatory: "Obligatorio",
  public: "Público",
  congratulations: "¡Felicidades!",
  rfi_title_module: "RFIs (Dudas)",
  no_rfis_title: "No se han creado RFIs (Dudas) hasta el momento",
  no_rfis_text:
    "Si deseas agregar uno, te invitamos a dar click en el botón de añadir para comenzar con el proceso",
  meeting: "Reunión",
  workflow: "Flujo",
  guideMe: "Guíame",
  support: "Soporte",
  days: "Días de respuesta",
  rfi_recycle_bin: "Papelera (RFIs)",
  // add steps
  step1_addDR_title: "1. Crear un nuevo reporte",
  step1_addDR_text: "Click aquí para añadir un nuevo reporte",
  step2_addDR_title: "2. Título",
  step2_addDR_text: "Escribe un título que pueda describir su nuevo reporte",
  step3_addDR_title: "3. Fecha de entrega",
  step3_addDR_text:
    "Elige una fecha limite como plazo para recibir la aprobación del asignado.",
  step4_addDR_title: "4. Estatus",
  step4_addDR_text1_1: "Elige el estatus",
  step4_addDR_text1_2: "ABIERTO para publicar el reporte como activo.",
  step4_addDR_text2_1: "Elige el estatus",
  step4_addDR_text2_2:
    "BORRADOR para guardar un borrador del reporte sin publicarlo.",
  step5_addDR_title: "5. Privacidad",
  step5_addDR_text_1: "Elige el tipo de privacidad del reporte",
  step5_addDR_text_2: "Cualquier persona con acceso al módulo verá tu reporte",
  step5_addDR_text_3: "Solo los participantes podrán ver este reporte",
  step6_addDR_title: "6. Creadores",
  step6_addDR_text:
    "Selecciona los usuarios que tendrán los mismos permisos que usted.",
  step7_addDR_title: "7. Asignados",
  step7_addDR_text: "Selecciona a las personas que aprobaran el reporte",
  step8_addDR_title: "8. Lista de distribución",
  step8_addDR_text:
    "Selecciona a las personas que se notificaran sobre este reporte.",
  step9_addDR_title: "9. Categoría",
  step9_addDR_text1_1: "Elige la categoría",
  step9_addDR_text1_2: "Básico para crear múltiples reportes.",
  step9_addDR_text2:
    "Avance de Obra para crear reportes con medidas de avance y costo.",
  step9_addDR_text3: "Problema para crear un solo reporte.",
  step9_addDR_text4:
    "Retraso para crear un reporte con días y horas de retraso.",
  step10_addDR_title: "10. Agrega un nuevo reporte",
  step10_addDR_text: "Click aquí para empezar un reporte.",
  step11_addDR_title: "11. Selecciona la ubicación",
  step11_addDR_text:
    "Click aquí para seleccionar la ubicación donde agregaras la descripción",
  step12_addDR_title: "12. Descripción",
  step12_addDR_text: "Escribe la descripción del progreso del trabajo",
  step13_addDR_title: "13. Adjunta archivos o fotos",
  step13_addDR_text:
    "Puedes adjuntar archivos o fotos para complementar la descripción",
  step14_addDR_title: "14. Añadir",
  step14_addDR_text: "Haga clic aquí para publicar el informe",
  stepFinal_addDR: "Haz aprendido cómo crear un reporte",
  // VIEW - guide me steps
  step1_viewDR_title: "1. Visualizar reporte",
  step1_viewDR_text: "Click aquí para visualizar el Reporte",
  step2_viewDR_title: "2. Categoría",
  step2_viewDR_text: "Visualiza la categoría a la que pertenece el reporte",
  step3_viewDR_title: "3. Información General",
  step3_viewDR_text: "Puedes ver la informacion completa del reporte",
  step4_viewDR_title: "{{stepNumber}}. Reportes por ubicación",
  step4_viewDR_text: "Visualizar la información detallada y archivos adjuntos",
  /* step4_viewDR_title: "4. Planos",
  step4_viewDR_text: "Puedes ver los planos adjuntos al reporte diario", */
  step5_viewDR_title: "{{stepNumber}}. Comentarios",
  step5_viewDR_text: "Puedes comentar sobre las ubicaciones reportadas",
  /* step6_viewDR_title: "6. Export PDF",
  step6_viewDR_text: "Genera un reporte PDF de los avances del proyecto", */
  step7_viewDR_title: "{{stepNumber}}. Aprobar",
  step7_viewDR_text: "Aprobar el reporte",
  stepFinal_viewDR: "Haz aprendido cómo ver un reporte",
  step_view_last_seen_title: "{{stepNumber}}. Última vista",
  step_view_last_seen_text:
    "Puede ver qué usuarios han visto recientemente este reporte",
  step_view_last_edit_title: "{{stepNumber}}. Última edición",
  step_view_last_edit_text:
    "Puede ver qué usuarios han interactuado recientemente con este reporte",
  step_view_export_title: "{{stepNumber}}. Exportar PDF",
  step_view_export_text:
    "Puede exportar un PDF con la información de este reporte",
  step_view_reports_description_title: "{{stepNumber}}. Descripción",
  step_view_reports_description_text:
    "Visualiza la descripción completa del reporte",
  step_view_report_progress_title: "{{stepNumber}}. Avance y Costo",
  step_view_report_progress_text:
    "Observa las medidas de avance y costo del reporte",
  // EDIT - guide me steps
  step1_editDR_title: "1. Editar reporte",
  step1_editDR_text: "Haz clic aquí para editar un reporte",
  step9_editDR_title: "{{stepNumber}}. Agrega un nuevo reporte",
  step9_editDR_text: "Click aquí para empezar un reporte.",
  step10_editDR_title: "{{stepNumber}}. Edita el reporte",
  step10_editDR_text: "Aquí puedes ver o editar la información del reporte",
  step11_editDR_title: "{{stepNumber}}. Archivos e información",
  step11_editDR_text: "Edita o agrega archivos de los reportes creados",
  step13_editDR_title: "{{stepNumber}}. Actualizar",
  step13_editDR_text: "Haga clic aquí para actualizar el reporte",
  stepFinal_editDR: "Haz aprendido a editar un reporte",
  step11_edit_report_progress_title: "{{stepNumber}}. Avance y costo",
  step11_edit_report_progress_text:
    "Edita o agrega las medidas de avance y costo del reporte",
  step10_edit_report_problem:
    "Modifica la descripción y los archivos del reporte",
  step9_edit_report_delay_title: "9. Retraso",
  step9_edit_report_delay_text:
    "Edita la cantidad de días u horas de retraso del reporte",
  step10_edit_report_delay:
    "Modifica la descripción y los archivos del reporte",
  // RECYCLE - guide me steps
  step1_recycleDR_title: "1. Papelera de Reportes",
  step1_recycleDR_text: "Haz clic aquí para abrir la papelera de reportes",
  step2_recycleDR_title: "2. Menú de la papelera",
  step2_recycleDR_text: "Este es el menú de la papelera de reportes",
  step3_recycleDR_title: "3. Ver un reporte",
  step3_recycleDR_text: "Puedes ver el reporte completo",
  step4_recycleDR_title: "4. Restaurar reporte",
  step4_recycleDR_text: "Selecciona uno o varios reportes para restaurar",
  step5_recycleDR_title: "5. Restaurar reportes seleccionados",
  step5_recycleDR_text:
    "Haz click aquí para restaurar los reportes seleccionados.",
  stepFinal_recycleDR:
    "Haz aprendido cómo interactuar con la papelera de Reportes",
  // EXPORT - guide me steps
  step1_exportDR_title: "1. Exportar PDF",
  step1_exportDR_text: "Haz clic aquí para exportar el reporte en formato PDF",
  step2_exportDR_title: "2. Enviar correo electrónico",
  step2_exportDR_text:
    "Haz clic aquí para compartir este reporte por correo electrónico",
  step3_exportDR_title: "2. Exportar CSV",
  step3_exportDR_text:
    "Selecciona algunos o filtra todos los reportes para exportarlos en un archivo CSV",
  step4_exportDR_title: "4. Exportar PDF",
  step4_exportDR_text:
    "Selecciona algunos o filtra todos los reportes para exportarlas en un archivo PDF",
  step5_exportDR_title: "3. Pantalla dentro de un reporte",
  step5_exportDR_text: "Puedes exportar un reporte desde la vista de detalle",
  stepFinal_exportDR: "Haz aprendido a exportar un reporte",
  // SETTINGS - guide me steps
  step2_settingsDR_title: "2. Configuración de reportes",
  step2_settingsDR_text: "Haz clic aquí para modificar la configuración",
  step3_settingsDR_title: "3. Lista de distribución",
  step3_settingsDR_text:
    "Modifica aquí los usuarios que pertenecen a la lista de distribución globalmente",
  step4_settingsDR_title: "4. Guardar configuración",
  step4_settingsDR_text: "Haz clic aquí para guardar la configuración",
  stepFinal_settingsDR:
    "Haz aprendido cómo interactuar con la configuración de los reportes",
  due: "Entrega",
  closed: "Cierre",
  basic: "Básico",
  workProgress: "Avance de obra",
  problem: "Problema",
  delay: "Retraso",
  advance: "Avance",
  unit: "Unidad",
  addUnit: "Agregar unidad",
  unitAdded: "Unidad agregada exitosamente",
  risk: "Riesgo",
  highRisk: "Alto",
  mediumRisk: "Medio",
  lowRisk: "Bajo",
  workerName: "Nombre del trabajador",
  jobType: "Tipo de trabajo",
  workedHours: "Horas trabajadas",
  assistants: "Asistentes",
  manpower: "Fuerza de trabajo",
  subcontractorMessage:
    "Hoy {{date}} asistieron un total de {{total}} personas. Favor de revisar.",
  job: "Trabajo",
  createReport: "Crear reporte",
  generalChart: "Gráfica general",
  approvedCorrectly: "Reporte aprobado\ncorrectamente",
  continueNavigate: "Puedes continuar navegando",
  noDailyReportCreated: "Todavía no tienes reportes creados",
  clickCreateReportToStart: "Haz clic en crear reporte para comenzar",
  approvedAtAndBy: "Aprobado el {{date}} por",
  sharedFrom: "Compartido desde",
  myEnterprise: "Mi empresa",
  externalEnterprise: "En empresa externa",
};
