import {
  CheckBox,
  CheckBoxOutlineBlank,
  ExpandMore,
  Search,
  TurnLeft,
} from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

function SharedByFilter({ options, value, onChange, title, icon }) {
  const { idEnterprise } = useParams();

  const { t: tDailyReports } = useTranslation("dailyReports");

  const [search, setSearch] = useState("");
  const [expanded, setExpanded] = useState([]);
  const [tab, setTab] = useState("1");
  const handleTabChange = (_, newValue) => {
    setTab(newValue);
  };

  const handleChange = (add, optionValue) => {
    if (add) {
      onChange([...value, optionValue]);
    } else {
      onChange(value.filter((val) => val !== optionValue));
    }
  };

  const handleSetExpanded = (tab) => {
    setExpanded((prev) => {
      const existTab = prev.find((item) => item === tab);
      return existTab ? prev.filter((item) => item !== tab) : [...prev, tab];
    });
  };

  const myEnterpriseItems = options.filter(
    (option) =>
      option.idEnterprise === idEnterprise &&
      option.name.toLowerCase().includes(search.toLowerCase()),
  );

  const externalEnterprises = Object.groupBy(
    options.filter(
      (option) =>
        option.idEnterprise === idEnterprise &&
        option.name.toLowerCase().includes(search.toLowerCase()),
    ),
    ({ enterpriseName }) => enterpriseName,
  );

  return (
    <Accordion variant="outlined" sx={{ border: "none" }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box display="flex" alignItems="center" gap={1}>
          {icon}
          <Typography height="20px">{title}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <TabContext value={tab}>
          <TabList onChange={handleTabChange} aria-label="sharedByFilter">
            <Tab
              label={tDailyReports("myEnterprise")}
              value="1"
              sx={{ textTransform: "none" }}
            />
            <Tab
              label={tDailyReports("externalEnterprise")}
              value="2"
              sx={{ textTransform: "none" }}
            />
          </TabList>
          <TextField
            InputProps={{
              startAdornment: <Search sx={{ color: "#E6B60C" }} />,
              sx: { borderRadius: "16px" },
            }}
            value={search}
            size="small"
            fullWidth
            onChange={(e) => setSearch(e.target.value)}
            sx={{ pt: 2 }}
          />
          <TabPanel value="1" sx={{ px: 0 }}>
            {myEnterpriseItems.map((option) => (
              <Box display="flex" key={option.id} alignItems="start">
                <Checkbox
                  checked={value.indexOf(option.id) > -1}
                  onChange={(e) => handleChange(e.target.checked, option.id)}
                  sx={{ pt: 0 }}
                />
                <Box>
                  <Typography fontSize="16px" fontWeight="500" color="#4B4B4B">
                    {option.name}
                  </Typography>
                  <Typography fontSize="14px" fontWeight="400" color="#8E8E8E">
                    {option.enterpriseName}
                  </Typography>
                </Box>
              </Box>
            ))}
          </TabPanel>
          <TabPanel value="2" sx={{ px: 0 }}>
            {Object.keys(externalEnterprises).map((key) => {
              return (
                <Accordion
                  key={key}
                  variant="outlined"
                  sx={{ border: "none" }}
                  expanded={expanded.includes(key)}
                  onChange={() => handleSetExpanded(key)}
                >
                  <AccordionSummary
                    sx={{ borderBottom: "1px solid lightgray" }}
                  >
                    <Box display="flex" alignItems="start" gap={1}>
                      {expanded.includes(key) ? (
                        <CheckBox color="primary" />
                      ) : (
                        <CheckBoxOutlineBlank />
                      )}
                      <Typography height="24px">{key}</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {externalEnterprises[key].map((option) => (
                      <Box
                        display="flex"
                        key={option.id}
                        alignItems="start"
                        justifyContent="space-between"
                      >
                        <Box display="flex" alignItems="center">
                          <TurnLeft
                            sx={{
                              color: "lightgray",
                              transform: "rotate(180deg)",
                              mr: 1,
                            }}
                          />
                          <Typography
                            fontSize="16px"
                            fontWeight="500"
                            color="#4B4B4B"
                          >
                            {option.name}
                          </Typography>
                        </Box>
                        <Checkbox
                          checked={value.indexOf(option.id) > -1}
                          onChange={(e) =>
                            handleChange(e.target.checked, option.id)
                          }
                          sx={{ pt: 0 }}
                        />
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </TabPanel>
        </TabContext>
      </AccordionDetails>
    </Accordion>
  );
}

export { SharedByFilter };
